import React, { useState, useRef, useEffect, Fragment } from "react";
import { Rnd } from "react-rnd";
import img from "./slog.png";
import slogh1 from "./slogh1.png";
import mitch from "./mitch.jpg";
import "./App.css";

const App = () => {
  const [uploadedImage, setUploadedImage] = useState(mitch);
  const [overlayImageSize, setOverlayImageSize] = useState({
    width: 150,
    height: 150,
  });
  const [overlayImagePosition, setOverlayImagePosition] = useState({
    x: 100,
    y: 30,
  });
  const [rotation, setRotation] = useState(0);
  const [flip, setFlip] = useState(false);
  const [imgMakerType, setImgMakerType] = useState("pfp");

  const canvasRef = useRef(null);
  const overlayImageRef = useRef(null);
  const subHeader = "AVevYR4Ban5oG5AMzJsRVEZsdgw5aQeDp8gM8qtRpump";

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const rotateOverlay = () => {
    setRotation((prevRotation) => prevRotation + 45);
  };

  const flipOverlay = () => {
    setFlip((prevFlip) => !prevFlip);
  };

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set canvas size to match the uploaded image
    const image = new Image();
    image.src = uploadedImage;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      // Calculate scale factors
      const scaleX =
        canvas.width /
        document.getElementById("screenshot-container").offsetWidth;
      const scaleY =
        canvas.height /
        document.getElementById("screenshot-container").offsetHeight;

      // Draw the uploaded image on the canvas
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Draw the overlay image on the canvas
      const overlayImage = new Image();
      overlayImage.src = img;
      overlayImage.onload = () => {
        // Apply transformations
        ctx.save();
        ctx.translate(
          (overlayImagePosition.x + overlayImageSize.width / 2) * scaleX,
          (overlayImagePosition.y + overlayImageSize.height / 2) * scaleY
        );
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.scale(flip ? -1 : 1, 1);
        ctx.drawImage(
          overlayImage,
          (-overlayImageSize.width / 2) * scaleX,
          (-overlayImageSize.height / 2) * scaleY,
          overlayImageSize.width * scaleX,
          overlayImageSize.height * scaleY
        );
        ctx.restore();

        // Create a link to download the image
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "image_with_overlay.png";
        link.click();
      };
    };
  };

  const pfp = () => (
    <Fragment>
      <input
        title="upload image"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
      <div className="control-buttons">
        <button onClick={rotateOverlay}>Rotate</button>
        <button onClick={flipOverlay}>Flip</button>
        <button onClick={downloadImage}>Download Image</button>
      </div>
      <div
        id="screenshot-container"
        style={{
          position: "relative",
          width: "300px",
          height: "300px",
          border: "1px solid #fff",
          marginTop: "5px",
        }}
      >
        {uploadedImage && (
          <img
            src={uploadedImage}
            alt="Uploaded"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {uploadedImage && (
          <Rnd
            size={{
              width: overlayImageSize.width,
              height: overlayImageSize.height,
            }}
            position={{
              x: overlayImagePosition.x,
              y: overlayImagePosition.y,
            }}
            onDragStop={(e, d) => {
              setOverlayImagePosition({ x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setOverlayImageSize({
                width: ref.style.width,
                height: ref.style.height,
              });
              setOverlayImagePosition({
                x: position.x,
                y: position.y,
              });
            }}
            lockAspectRatio
            bounds="parent"
            enableResizing={{
              top: true,
              right: true,
              bottom: true,
              left: true,
              topRight: true,
              bottomRight: true,
              bottomLeft: true,
              topLeft: true,
            }}
          >
            <div
              ref={overlayImageRef}
              className="overlay-container"
              style={{
                width: "100%",
                height: "100%",
                background: `url(${img}) no-repeat center center / contain`,
                transform: `rotate(${rotation}deg) scaleX(${flip ? -1 : 1})`,
                position: "relative",
              }}
            />
          </Rnd>
        )}
      </div>
    </Fragment>
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={slogh1} style={{ width: "300px" }} />{" "}
        <h6 onClick={() => navigator.clipboard.writeText(subHeader)}>
          {subHeader}
        </h6>
        {/* <div>
          <button onClick={() => setImgMakerType("pfp")}>PFP Slogger</button>
          <button onClick={() => setImgMakerType("banner")}>Meme Maker</button>
        </div> */}
        {imgMakerType === "pfp" ? pfp() : null}
      </header>
    </div>
  );
};

export default App;
